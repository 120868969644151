import axios from "axios";


export interface ChurchEvent {
  title: string;
  description: string;
  location: string;
  date: string;
  time: string;
}

// autogenerated
// Function which queries the information from the 
// excel sheet
export async function getChurchEvents() {
  const sheetId = process.env.EVENTS_SHEET_ID;
  const apiKey = process.env.GOOGLE_API_KEY;
  // const rangeLastTenEvents = 'J5:N15';
  const response = await axios.get(
    `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/Events?alt=json&key=${apiKey}`
  );


  // Extract headers and events data
  const [headers, ...events]: Array<Array<string>> = response.data.values;

  // Convert events to an array of objects
  var eventsArray: Array<ChurchEvent> = events.map((event) => {
    const eventObject: ChurchEvent = {} as ChurchEvent;
    headers.forEach((header, index) => {
      if (event[index] && event[index].trim().length > 0) {
        eventObject[header.toLowerCase() as keyof ChurchEvent] = event[index];
      }
    });
    return eventObject;
  });

  eventsArray = eventsArray.filter(value => Object.keys(value).length >= 5);


  // Sort events by date and time
  eventsArray.sort((a, b) => {
    const dateA = new Date(`${a.date} ${a.time}`);
    const dateB = new Date(`${b.date} ${b.time}`);
    return dateA.getTime() - dateB.getTime();
  });

  return eventsArray
}

// autogenerated
export function getNextNEvents(events: Array<ChurchEvent>, n: number): Array<ChurchEvent> {
  const now = new Date();

  // Filter events happening after the current time
  const futureEvents = events.filter((event) => {
    const eventDateTime = new Date(`${event.date} ${event.time}`);
    return eventDateTime > now;
  });

  // Sort the filtered events by date and time
  futureEvents.sort((a, b) => {
    const dateA = new Date(`${a.date} ${a.time}`);
    const dateB = new Date(`${b.date} ${b.time}`);
    return dateA.getTime() - dateB.getTime();
  });

  // Return the next three events
  return futureEvents.slice(0, n);
}