import React from "react";
import * as API from "../../generated/api";
import { prettyPrintBytes, formatTimestamp } from "@components/utils";
import FileIcon from "@icons/File";
import BinIcon from "@icons/Bin";
import FileDownloadIcon from "@icons/FileDownload";

interface LibraryTableFileProps {
  file: API.FileDetails;
  idx: number;
  handleFileDownload: Function;
  handleFileRemove: Function;
}

export function LibraryTableFile({
  file,
  idx,
  handleFileDownload,
  handleFileRemove,
}: LibraryTableFileProps) {
  return (
    <tr key={idx} className="text-center">
      <td className="py-2 px-4 border-b">
        <FileIcon />
      </td>
      <td className="py-2 px-4 border-b">{file.file_name}</td>
      <td className="py-2 px-4 border-b">{prettyPrintBytes(file.size)}</td>
      <td className="py-2 px-4 border-b">
        {file.created_at ? formatTimestamp(file.created_at) : "N/A"}
      </td>
      <td className="py-2 px-4 border-b">
        {file.modified_at ? formatTimestamp(file.modified_at) : "N/A"}
      </td>
      <td className="py-2 px-4 border-b">
        <div className="flex flex-row gap-x-2 justify-center">
          <button onClick={() => handleFileDownload(file.file_name)}>
            <FileDownloadIcon />
          </button>
          <button onClick={() => handleFileRemove(file.file_name, idx)}>
            <BinIcon />
          </button>
        </div>
      </td>
    </tr>
  );
}
