import React, { useContext, ReactNode } from "react";
import { AuthContext } from "@components/AuthContext";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ component }: { component: ReactNode }) => {
  const authContext = useContext(AuthContext);

  return (
    // loading
    authContext?.authenticated === undefined ? (
      <div></div>
    ) : // Render child if authenticated, redirect to login if not authenticated
    authContext?.authenticated === true ? (
      component
    ) : (
      <Navigate to={"/log-in"} />
    )
  );
};
