import React, { useState } from "react";
import * as API from "../../generated/api";
import { prettyPrintBytes, formatTimestamp } from "@components/utils";
import FolderIcon from "@icons/Folder";
import FolderOpenIcon from "@icons/FolderOpen";
import PencilIcon from "@icons/Pencil";
import BinIcon from "@icons/Bin";
import { FolderActionModal } from "@components/FolderActionModal";
import path from "path-browserify";
import { InfoModal } from "@components/InfoModal";
import InfoIcon from "@icons/Info";

interface LibraryTableFolderMobileProps {
  folder: API.FolderDetails;
  idx: number;
  handleFolderRename: Function;
  handleFolderRemove: Function;
  handleFolderOpen: Function;
}

export function LibraryTableFolderMobile({
  folder,
  idx,
  handleFolderRename,
  handleFolderRemove,
  handleFolderOpen,
}: LibraryTableFolderMobileProps) {
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState("");

  return (
    <tr key={idx} className="text-center">
      <td className="py-2 px-4 border-b">
        <FolderIcon />
      </td>
      <td className="py-2 px-4 border-b">{folder.folder_name}</td>
      {/* <td className="py-2 px-4 border-b">{prettyPrintBytes(folder.size)}</td> */}
      <td className="py-2 px-4 border-b">
        <div className="flex flex-row gap-x-2 justify-center">
          <button
            onClick={() => {
              handleFolderOpen();
            }}
          >
            <FolderOpenIcon />
          </button>
          <button
            onClick={() => {
              setIsFolderModalOpen(true);
            }}
          >
            <PencilIcon />
          </button>
          <button
            onClick={() => {
              handleFolderRemove();
            }}
          >
            <BinIcon />
          </button>

          <button
            onClick={() => {
              setIsInfoModalOpen(true);
              setInfoModalMessage(
                `Ordnergröße: ${prettyPrintBytes(folder.size)}`
              );
            }}
          >
            <InfoIcon />
          </button>

          <InfoModal
            show={isInfoModalOpen}
            title={"Ordnerinformationen"}
            message={infoModalMessage}
            onClose={() => setIsInfoModalOpen(false)}
          />
          <FolderActionModal
            title={"Ordner umbenennen"}
            show={isFolderModalOpen}
            onClose={() => setIsFolderModalOpen(false)}
            onSubmit={(folder_new_name: string) => {
              const currentFolderPath =
                sessionStorage.getItem("currentFolderPath") || "./";
              handleFolderRename(
                path.join(currentFolderPath, folder.folder_name),
                folder_new_name
              );
            }}
          />
        </div>
      </td>
    </tr>
  );
}
