import React, { useState, createContext, useEffect, ReactNode } from "react";
import * as API from "../../generated/api";
import { LOCAL_API_SERVER, PROD_API_SERVER } from "../../consts";

interface AuthContextState {
  authenticated: boolean | undefined;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const AuthContext = createContext<AuthContextState | null >(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authenticated, setAuthenticated] = useState<boolean | undefined>(undefined);
  // const [isLoadingAuth, setIsLoadingAuth] = useState(true);

  useEffect(() => {
    // setIsLoadingAuth(true);
    // console.log(process.env.APP_ENV);
    const authApi = new API.AuthRoutesApi(
      undefined,
      process.env.APP_ENV == "DEV" ? LOCAL_API_SERVER : PROD_API_SERVER
    );
    authApi
      .apiV1AuthIsLoggedGet({ withCredentials: true })
      .then((response) => {
        // console.log('user authenticated from server!')
        setAuthenticated(true);
      })
      .catch((err) => {
        // console.log('user not authenticated from server!')
        setAuthenticated(false);
      });
      // setIsLoadingAuth(false);
  }, []);

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
