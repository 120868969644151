import React, { useState } from "react";

interface FolderActionModalProps {
  show: boolean;
  title: string;
  onClose: Function;
  onSubmit: Function;
}
export function FolderActionModal({
  show,
  title,
  onClose,
  onSubmit,
}: FolderActionModalProps) {
  if (!show) return null;
  const [folderName, setFolderName] = useState("");
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg relative max-w-md mx-auto shadow-lg">
        <button
          className="absolute top-2 right-2 text-gray"
          onClick={() => {
            onClose();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-lg font-bold mb-4 text-start">{title}</h2>
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-row gap-4">
            <label className="self-center"> Ordnername: </label>
            <input
              onChange={(e) => setFolderName(e.target.value)}
              className="border rounded-md p-2"
              placeholder="Bitte Ordnernamen eingeben"
            ></input>
          </div>
          <div className="flex-row justify-end flex">
            <button
              className="px-2 py-2 bg-c-green text-white rounded w-20"
              onClick={() => {
                onSubmit(folderName);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
