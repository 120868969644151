import React, { useState } from "react";
import * as API from "../../generated/api";
import { prettyPrintBytes, formatTimestamp } from "@components/utils";
import { InfoModal } from "@components/InfoModal";
import FileIcon from "@icons/File";
import FileDownloadIcon from "@icons/FileDownload";
import BinIcon from "@icons/Bin";
import InfoIcon from "@icons/Info";

interface LibraryTableFileMobileProps {
  file: API.FileDetails;
  idx: number;
  handleFileDownload: Function;
  handleFileRemove: Function;
}

export function LibraryTableFileMobile({
  file,
  idx,
  handleFileDownload,
  handleFileRemove,
}: LibraryTableFileMobileProps) {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState("");
  return (
    <tr key={idx} className="text-center">
      <td className="py-2 px-4 border-b">
        <FileIcon />
      </td>
      <td className="py-2 px-4 border-b break-all w-1/2">{file.file_name}</td>
      <td className="py-2 px-4 border-b">
        <div className="flex flex-row gap-x-2 justify-center">
          <button onClick={() => handleFileDownload(file.file_name)}>
            <FileDownloadIcon />
          </button>
          <button onClick={() => handleFileRemove(file.file_name, idx)}>
            <BinIcon />
          </button>
          <button
            onClick={() => {
              setIsInfoModalOpen(true);
              setInfoModalMessage(
                `Dateigröße: ${prettyPrintBytes(
                  file.size
                )}\nErstellungsdatum: ${
                  file.created_at ? formatTimestamp(file.created_at) : "N/A"
                }\n Änderungsdatum: ${
                  file.modified_at ? formatTimestamp(file.modified_at) : "N/A"
                }`
              );
            }}
          >
            <InfoIcon />
          </button>
        </div>

        <InfoModal
          show={isInfoModalOpen}
          title={"Dateiinformationen"}
          message={infoModalMessage}
          onClose={() => setIsInfoModalOpen(false)}
        />
      </td>
    </tr>
  );
}
