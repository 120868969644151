import { getWindowDimensions } from "@components/utils";
import React, { useEffect, useState } from "react";
import * as API from "../../generated/api";
import {
  LibraryTableFile,
  LibraryTableFileMobile,
} from "@components/LibraryTableFile";
import {
  LibraryTableFolder,
  LibraryTableFolderMobile,
} from "@components/LibraryTableFolder";
import path from "path-browserify";

interface LibraryTableProps {
  storageData: API.StorageFilesAndFolders;
  handleFileDownload: Function;
  handleFileRemove: Function;
  handleFolderRemove: Function;
  handleFolderRename: Function;
  currentFolderPath: string;
  setCurrentFolderPath: Function;
}

export function LibraryTable({
  storageData,
  handleFileDownload,
  handleFileRemove,
  handleFolderRemove,
  currentFolderPath,
  setCurrentFolderPath,
  handleFolderRename,
}: LibraryTableProps): JSX.Element {
  const { width } = getWindowDimensions();

  // todo: enhance the responsiveness without using the if statement
  if (width < 1024) {
    return (
      <div className="mx-auto overflow-y-auto max-h-[60vh] rounded-2xl border-2 border-c-main">
        <table className="min-w-full">
          <thead className="bg-c-background">
            <tr>
              <th className="py-2 px-4 border-b">Type</th>
              <th className="py-2 px-4 border-b w-1/2">Name</th>
              <th className="py-2 px-4 border-b">Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {storageData &&
              storageData.files.map((file, idx) => (
                <LibraryTableFileMobile
                  file={file}
                  idx={idx}
                  handleFileDownload={handleFileDownload}
                  handleFileRemove={handleFileRemove}
                />
              ))}

            {storageData &&
              storageData.folders &&
              storageData.folders.map((folder, idx) => (
                <LibraryTableFolderMobile
                  folder={folder}
                  idx={storageData.files.length + idx}
                  handleFolderRename={handleFolderRename}
                  handleFolderRemove={() => {
                    handleFolderRemove(
                      path.join(currentFolderPath, folder.folder_name)
                    );
                  }}
                  handleFolderOpen={() => {
                    setCurrentFolderPath(
                      path.join(currentFolderPath, folder.folder_name) + "/"
                    );
                  }}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="mx-auto overflow-y-auto max-h-[60vh] rounded-2xl border-2 border-c-main">
        <table className="min-w-full">
          <thead className="bg-c-background">
            <tr>
              <th className="py-2 px-4 border-b">Type</th>
              <th className="py-2 px-4 border-b">Name</th>
              <th className="py-2 px-4 border-b">Dateigröße</th>
              <th className="py-2 px-4 border-b">Erstellungsdatum</th>
              <th className="py-2 px-4 border-b">Änderungsdatum</th>
              <th className="py-2 px-4 border-b">Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {storageData &&
              storageData.files.map((file, idx) => (
                <LibraryTableFile
                  file={file}
                  idx={idx}
                  handleFileDownload={handleFileDownload}
                  handleFileRemove={handleFileRemove}
                />
              ))}

            {storageData &&
              storageData.folders &&
              storageData.folders.map((folder, idx) => (
                <LibraryTableFolder
                  folder={folder}
                  idx={storageData.files.length + idx}
                  handleFolderRename={handleFolderRename}
                  handleFolderRemove={() => {
                    handleFolderRemove(
                      path.join(currentFolderPath, folder.folder_name)
                    );
                  }}
                  handleFolderOpen={() => {
                    setCurrentFolderPath(
                      path.join(currentFolderPath, folder.folder_name) + "/"
                    );
                  }}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
