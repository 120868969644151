import { AuthContext } from "@components/AuthContext";
import React, { useEffect, useContext, useState, useRef } from "react";
import { LOCAL_API_SERVER, PROD_API_SERVER } from "../../consts";
import * as API from "../../generated/api";
import { LibraryTable } from "@components/LibraryTable";
import { prettyPrintBytes } from "@components/utils";
import { FolderActionModal } from "@components/FolderActionModal";
import FolderCreateIcon from "@icons/FolderCreate";
import FileUploadIcon from "@icons/FileUpload";
import ArrowLeftIcon from "@icons/ArrowLeft";
import { Oval } from "react-loader-spinner";
import path from "path-browserify";

export const Bibliothek = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [currentFolderPath, setCurrentFolderPath] = useState(
    sessionStorage.getItem("currentFolderPath") || "./"
  );
  const [isLoading, setIsLoading] = useState(true);

  const authContext = useContext(AuthContext);
  const [storageData, setStorageData] = useState<API.StorageFilesAndFolders>({
    files: [],
    folders: [],
    remaining_bytes: 0,
  });
  // Hardcoded file data

  const storageApi = new API.StorageRoutesApi(
    undefined,
    process.env.APP_ENV == "DEV" ? LOCAL_API_SERVER : PROD_API_SERVER
  );

  // Event handlers
  const handleFolderRename = (folderPath: string, folderNewName: string) => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await storageApi.apiV1StorageDirUpdatePost(
          folderPath,
          folderNewName,
          {
            withCredentials: true,
          }
        );
        if (response.status == 200) {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error renaming folder:", error);
      }
      setIsLoading(false);
    })();
  };

  const handleFolderRemove = (folderPath: string) => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await storageApi.apiV1StorageDirDeleteDelete(
          folderPath,
          {
            withCredentials: true,
          }
        );
        if (response.status == 200) {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error removing folder:", error);
      }
      setIsLoading(false);
    })();
  };

  const handleFolderCreate = (folderName: string) => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await storageApi.apiV1StorageDirCreatePost(
          folderName,
          currentFolderPath,
          {
            withCredentials: true,
          }
        );
        if (response.status == 200) {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error creating folder:", error);
      }
      setIsLoading(false);
    })();
  };

  const handleFileDownload = (fileName: string) => {
    // Placeholder for download logic
    // console.log(`Downloading file: ${fileName}`);
    // https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
    const filePath = path.join(currentFolderPath, fileName);
    // console.log("Download File", filePath);
    (async () => {
      setIsLoading(true);
      const response = await storageApi
        .apiV1StorageFileDownloadGet(filePath, {
          withCredentials: true,
          responseType: "blob",
        })
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
      setIsLoading(false);
    })();
  };

  const handleFileRemove = (fileName: string, index: number) => {
    // Placeholder for remove logic
    // console.log(`Removing file: ${fileName}`);
    const filePath = path.join(currentFolderPath, fileName);
    (async () => {
      setIsLoading(true);
      try {
        const response = await storageApi.apiV1StorageFileDeleteDelete(
          filePath,
          {
            withCredentials: true,
          }
        );
        if (response.status == 200) {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
      setIsLoading(false);
    })();
  };

  const handleHiddenUploadButtonClick = () => {
    // Trigger the click event of the hidden file input
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files == null) {
      return;
    }
    const selectedFile = event.target.files[0];

    setIsLoading(true);
    try {
      const response = await storageApi.apiV1StorageFileUploadPost(
        currentFolderPath,
        selectedFile,
        {
          withCredentials: true,
        }
      );
      console.log("File: ", selectedFile.name, "Uploaded.");
      if (response.status === 200) {
        // refresh to handle duplicate files upload
        window.location.reload();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    sessionStorage.setItem("currentFolderPath", currentFolderPath);

    (async () => {
      setIsLoading(true);
      const response = await storageApi.apiV1StorageDirGetAllGet(
        currentFolderPath,
        {
          withCredentials: true,
        }
      );
      if (response.status == 200) {
        const storageData: API.StorageFilesAndFolders = response.data;
        setStorageData(storageData);
      }
      setIsLoading(false);
    })();
  }, [currentFolderPath]);

  return (
    <div className="max-w-screen-xl min-w-[100%]">
      <FolderActionModal
        title={"Ordner erstellen"}
        show={isFolderModalOpen}
        onClose={() => setIsFolderModalOpen(false)}
        onSubmit={handleFolderCreate}
      />
      <div className=""></div>
      <div>
        <h2 className="text-4xl font-bold mb-4">Bibliothek</h2>
        <div className="flex felx-row gap-x-2 justify-end">
          <button
            className="px-2 py-2 bg-c-green text-white rounded flex justify-center items-center"
            onClick={() => setIsFolderModalOpen(true)}
          >
            Ordner erstellen
            <FolderCreateIcon />
          </button>
          <button
            className="px-2 py-2 bg-c-green text-white rounded flex justify-center items-center"
            onClick={handleHiddenUploadButtonClick}
          >
            Hochladen
            <FileUploadIcon />
          </button>
        </div>

        {/* Hidden file input to upload files */}
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>

      {storageData && (
        <h2 className="text-xl p-2">
          Verfügbarer Speicherplatz:
          {prettyPrintBytes(storageData.remaining_bytes)}
        </h2>
      )}
      <p className="p-2">Path: {currentFolderPath}</p>
      {currentFolderPath !== "./" && (
        <button
          className="p-2 flex flex-end self-end ml-auto"
          onClick={() => {
            setCurrentFolderPath(path.dirname(currentFolderPath) + "/");
          }}
        >
          <ArrowLeftIcon />
        </button>
      )}

      {!isLoading ? (
        <LibraryTable
          storageData={storageData}
          handleFileDownload={handleFileDownload}
          handleFileRemove={handleFileRemove}
          setCurrentFolderPath={setCurrentFolderPath}
          currentFolderPath={currentFolderPath}
          handleFolderRemove={handleFolderRemove}
          handleFolderRename={handleFolderRename}
        />
      ) : (
        <Oval
          width={40}
          wrapperClass="flex justify-center"
          color="black"
          strokeWidth={5}
        />
      )}
    </div>
  );
};
